import Axios from "axios";
import add from "date-fns/add";
import isAfter from "date-fns/isAfter";
import isPast from "date-fns/isPast";
import isToday from "date-fns/isToday";
import { Constants } from "../@types/Constants";
import { ISort } from "../@types/ISort";
import { coreGet } from "../services/core";
import { formatDate, searchDateFormat } from "../services/dates";
import { Storage } from "./storage";
import { store } from "../redux/store";
import IURLSearchParams from "../@types/IURLSearchParams";
import queryString from "query-string";
import moment from "moment-mini";
export const isBrowser = typeof window !== "undefined";
export const convertArrayToObject = (array: Array<any>, key: string): any => {
  const initialValue = {};
  if (Array.isArray(array)) {
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  } else {
    return initialValue;
  }
};

export const getQueryParameters = (
  queryString?: string
): {
  [k: string]: any;
} | null => {
  if (!queryString) return null;
  const urlParams: any = new URLSearchParams(queryString);
  return Object.fromEntries(urlParams);
};

export const priceFormatter = (
  price: number,
  decimals = 2,
  currencyCode = "USD"
): string | number => {
  let formattedPrice: string | null = null;
  if ((price || price === 0) && !isNaN(price)) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode || "USD",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
    formattedPrice = formatter.format(price);
  }
  return formattedPrice || price;
};

export const getCoreSessionToken = async (): Promise<string | null> => {
  let token = null;
  const restUrl = `${process.env.GATSBY_CORE_BASE_URL}/rest/session/token`;
  try {
    const response = await Axios.get(restUrl);
    token = await response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return token;
};

export const isEmptyObject = (obj: { [key: string]: string }): boolean => {
  for (const x in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, x)) return false;
  }
  return true;
};

export const formatCurrency = (num: number, decimals = 2): string => {
  return "$" + num.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getLocationPlaceId = async (params: any): Promise<string> => {
  const inputStr = encodeURIComponent(params.location);
  const apiUrl = `/placeid/${params.lat},${params.lon}/${inputStr}`;

  let locationPlaceId = null;

  try {
    const cacheKey = `pc_${params.lat},${params.lon}`;
    locationPlaceId = Storage.GetLocalStorageValue(cacheKey);
    if (!locationPlaceId) {
      const response = await coreGet(apiUrl);
      locationPlaceId = response.placeid;
      Storage.SetLocalStorageValue(cacheKey, locationPlaceId);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return locationPlaceId;
};

export const sortRooms = (unsortedRooms: Array<ISort>): ISort[] => {
  const availableRooms = [];
  const soldoutRooms: Array<ISort> = [];
  let soldoutAccessibleRooms: Array<ISort> = [];
  unsortedRooms.forEach((r) => {
    if (!r.Availability || r.Availability < 1) {
      const rname = r.Name?.toLowerCase();
      if (
        rname &&
        (rname.indexOf("accessible") !== -1 || rname.indexOf("ada") !== -1)
      ) {
        soldoutAccessibleRooms.push(r);
      } else {
        soldoutRooms.push(r);
      }
    } else {
      availableRooms.push(r);
    }
  });
  const sortedSoldoutRooms = soldoutRooms.sort(
    (r1: { Name: string }, r2: { Name: string }) => {
      if (r1.Name < r2.Name) return -1;
      else if (r1.Name > r2.Name) return 1;
      else return 0;
    }
  );
  soldoutAccessibleRooms = soldoutAccessibleRooms.sort(
    (r1: { Name: string }, r2: { Name: string }) => {
      if (r1.Name < r2.Name) return -1;
      else if (r1.Name > r2.Name) return 1;
      else return 0;
    }
  );

  availableRooms.push(...sortedSoldoutRooms);
  availableRooms.push(...soldoutAccessibleRooms);
  return availableRooms;
};

export const gotoTab = (eleId: string, isMobileView = false): void => {
  const targetElm = document.getElementById(eleId);
  if (targetElm) {
    const elementRect = targetElm.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    const middle = absoluteElementTop; // - (window.innerHeight / 2);
    const adjust = isMobileView ? 270 : 120;
    window.scrollTo(0, middle - adjust);
    // targetElm.scrollIntoView({block: "nearest"});
  }
};

export const addHiddenIframe = (id: string, src: string): void => {
  const iframe = document.createElement("iframe");
  iframe.id = id;
  iframe.style.display = "none";
  iframe.src = src;
  document.body.appendChild(iframe);
};

export const getSearchPayloadStayDates = (
  checkinDate: Date | undefined | null,
  checkoutDate: Date | undefined | null,
  lengthOfStay: number | null,
  leadDays: number | null,
  hotelDefaultCheckinDate: string | number | null
): { formedCheckin: string | null; formedCheckout: string | null } => {
  let payloadCheckin = null;
  let payloadCheckout = null;
  if (checkinDate && checkoutDate) {
    payloadCheckin = checkinDate;
    payloadCheckout = checkoutDate;
  } else {
    if (
      !checkinDate &&
      leadDays &&
      leadDays &&
      leadDays > 0 &&
      leadDays < 365
    ) {
      const today = new Date();
      payloadCheckin = add(today, { days: leadDays });
      payloadCheckout =
        checkoutDate &&
        !isPast(checkoutDate) &&
        isAfter(checkoutDate, payloadCheckin)
          ? checkoutDate
          : add(payloadCheckin, { days: 1 });
    }
    if (
      !checkinDate &&
      hotelDefaultCheckinDate &&
      isAfter(new Date(hotelDefaultCheckinDate), new Date())
    ) {
      payloadCheckin = new Date(hotelDefaultCheckinDate);
      payloadCheckout =
        checkoutDate &&
        !isPast(checkoutDate) &&
        isAfter(checkoutDate, payloadCheckin)
          ? checkoutDate
          : add(payloadCheckin, { days: 1 });
    }
    if (
      !checkoutDate &&
      lengthOfStay &&
      lengthOfStay > 0 &&
      lengthOfStay < 365
    ) {
      payloadCheckin = checkinDate || payloadCheckin || new Date();
      payloadCheckout = add(payloadCheckin, { days: lengthOfStay });
    }
  }
  if (
    payloadCheckin &&
    payloadCheckout &&
    (!isPast(payloadCheckin) || isToday(payloadCheckin)) &&
    isAfter(payloadCheckout, payloadCheckin)
  ) {
    return {
      formedCheckin: formatDate(payloadCheckin, searchDateFormat),
      formedCheckout: formatDate(payloadCheckout, searchDateFormat),
    };
  } else {
    return {
      formedCheckin: null,
      formedCheckout: null,
    };
  }
};

export const getCRSSubSource = (): string => {
  const subSourceFromURL = sessionStorage[Constants.CRS_SUB_SOURCE];
  const subSource = subSourceFromURL
    ? subSourceFromURL
    : process.env.GATSBY_CRS_SUB_SOURCE;
  return subSource || "";
};

export const setCRSSubSourceFromURL = (
  locationSearch: string | undefined
): void => {
  const queryParams = locationSearch ? getQueryParameters(locationSearch) : {};
  if (queryParams?.subSource) {
    sessionStorage[Constants.CRS_SUB_SOURCE] = queryParams.subSource.trim();
  }
};

export const getLoggedStatus = (): string => {
  const state = store.getState();

  const isLoggedin = state?.member?.isLoggedIn; //Storage.GetLocalStorageValue('isLoggedIn');
  return isLoggedin ? "Logged In" : "Logged Out";
};

export const getLoggedUserInfo = (): {
  email: string | undefined;
  profileType: string | undefined;
} => {
  const state = store.getState();

  const member = state?.member; //Storage.GetLocalStorageValue('isLoggedIn');

  return {
    email: member?.profile?.email,
    profileType: member?.crmProfile?.profileType,
  };
};

export const convertToWord = (stringVal: string): string => {
  return stringVal
    .split("-")
    .map((word) => word[0]?.toUpperCase() + word.substring(1))
    .join(" ");
};

export const extractBrandFromUrl = (search: string): [] => {
  let brandsArr = [];
  if (search.length) {
    const qp = queryString.parse(search, {
      parseBooleans: true,
      arrayFormat: "index",
    });
    const queryParams = Object.keys(qp).reduce((_qp, key) => {
      _qp[key.toLowerCase()] = qp[key];
      return _qp;
    }, {} as IURLSearchParams);
    if (queryParams[`brand`]) {
      brandsArr = queryParams[`brand`]
        .split("|")
        .map((str: string) => str.toLowerCase());
    }
  }
  return brandsArr;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const trimOccupancyData = (rooms: any) => {
  return rooms.map((item: any) => {
    // Check if rate and room properties exist
    if (!item.rate || !item.room) {
      // If any of them is missing, return the original item
      return item;
    }

    // Destructure rate, room, and the remaining properties
    const { rate, room, ...rest } = item;

    // Create a trimmed version of the rate object
    const trimmedRate = { ...rate };
    // Delete unnecessary properties from the trimmed rate object
    delete trimmedRate.currencyCode;
    delete trimmedRate.description;
    delete trimmedRate.guarantee;
    delete trimmedRate.resortFee;
    delete trimmedRate.roomRateNightly;
    delete trimmedRate.taxItems;
    delete trimmedRate.sort;

    // Create a trimmed version of the room object
    const trimmedRoom = { ...room };
    // Delete unnecessary properties from the trimmed room object
    delete trimmedRoom.amenities;
    delete trimmedRoom.description;
    delete trimmedRoom.imageUrls;
    delete trimmedRoom.galleryImages;
    delete trimmedRoom.Rates;
    delete trimmedRoom.services;

    // Merge the remaining properties with the trimmed rate and room objects
    return { ...rest, rate: trimmedRate, room: trimmedRoom };
  });
};

export const formatRedemptionItem = (currency: number): string => {
  const formattedCurrency = Number(currency).toFixed();
  return Number(formattedCurrency).toLocaleString();
};

export const extractBiasFromUrl = (
  search: string
): string | string[] | null => {
  let biasValue = null;

  if (search.length) {
    const queryParams = queryString.parse(search);

    if (queryParams["bias"]) {
      biasValue = queryParams["bias"];
    }
  }

  return biasValue;
};

export const getUserTripsDataforGA = (trips: any) => {
  let upcomingTrip;
  let daysLeftForUpcomingTrip;
  let hotelIdOfLastStay;

  const upcomingStays: any[] = [];
  const recentStays: any[] = [];

  if (trips && trips.length > 0) {
    trips &&
      trips.forEach((stay: any) => {
        const trip = stay.reservations[0];
        if (trip.confirmNumber) {
          const res = {
            arrival: trip.startDate,
            departure: trip.endDate,
            hotelId: trip.hotel.id,
          };
          if (trip.status == "COMPLETED") {
            recentStays.push(res);
          } else if (trip.status == "UPCOMING") {
            if (moment(trip.startDate).isBefore(moment())) {
              recentStays.push(res);
            } else {
              upcomingStays.push(res);
            }
          }
        }
      });
  }

  if (upcomingStays.length > 0) {
    upcomingTrip = true;
    const nextTrip = upcomingStays[0];

    const futureDate = moment(nextTrip?.arrival, "YYYY-MM-DD");
    const currentDate = moment();
    daysLeftForUpcomingTrip = futureDate.diff(currentDate, "days");
  } else {
    upcomingTrip = false;
    daysLeftForUpcomingTrip = "NA";
  }

  if (recentStays.length > 0) {
    const lastTrip = recentStays[0];
    hotelIdOfLastStay = lastTrip.hotelId;
  } else {
    hotelIdOfLastStay = "NA";
  }

  return { upcomingTrip, daysLeftForUpcomingTrip, hotelIdOfLastStay };
};
