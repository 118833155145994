import { gql } from "@apollo/client";

export const GET_BASIC_PROFILE_QUERY = gql`
  query GetCDPUserProfile{
    cDPUserProfile {
      memberId
      gender
      title
      birthday {
        day
        month
      }
      address {
        country
        locality
        postalCode
        region
        streetAddress1
        streetAddress2
      }
      createdDate
      email
      firstName
      id
      interests {
        businessTravel
        leisureTravel
      }
      lastName

      phones {
        work
        home
        mobile
      }
      profileType
      rewardPoints
      rewards {
        description
        isEligible
        operation
        rewardPoints
        rewardTransactionNo
        transactionDate
      }
      trips {
        itineraryId
        reservations {
          redemption {
            redeemedPoints
          }
          billing {
            costEstimate
          }
          confirmNumber
          guestFirstName
          guestLastName
          endDate
          hotel {
            address {
              country
              locality
              postalCode
              region
              streetAddress1
              streetAddress2
            }
            cmsHotelCode
            cmsHotelId
            email
            geoCoordinate {
              latitude
              longitude
            }
            id
            imageUrl
            name
            telephone
          }
          itineraryId
          loyaltyProfile {
            firstName
            id
            lastName
          }
          numberOfNights
          policy {
            checkInTime
            checkOutTime
          }
          reward {
            description
            isEligible
            operation
            rewardPoints
            rewardTransactionNo
            transactionDate
          }
          room {
            adultCount
            childCount
            roomType
          }
          startDate
          status
          visitLogNo
          status
        }
      }
    }
  }
`;

export const EDIT_USER_PROFILE_MUTATION = gql`
      mutation EditUserProfile(
        $updateUserProfileRequest: UpdateUserProfileRequestInput!
      ) {
        editUserProfile(updateUserProfileRequest: $updateUserProfileRequest) {
          message
          statusCode
        }
      }
    `;
