import { differenceInDays } from "date-fns"; // Importing the specific function from date-fns
import { getLoggedStatus, getLoggedUserInfo } from "./helpers";
import {
  Details,
  Hotel,
  HotelBrand,
  Payment,
  Product,
  Reservation,
  Room,
  Search,
} from "../@types/IDatalayers";
import { TP_REDEMPTION_RATE } from "../@types/Constants";

declare global {
  interface Window {
    [key: string | number]: any;
  }
}
// Add type annotations for the function parameters and return types
async function sha256(string: string): Promise<string> {
  const utf8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest("SHA-256", utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}
export const addToCartGTMDataLayer = (
  hotel: Hotel,
  room: {
    name: string;
  },
  rate: {
    rateCode: string;
  },
  price: number,
  checkin: string,
  checkout: string,
  totalRooms: number
): void => {
  const totalNights = differenceInDays(new Date(checkout), new Date(checkin));
  const brandName =
    hotel.relationships &&
    hotel.relationships.brand_id &&
    hotel.relationships.brand_id.name;
  const loggedState = getLoggedStatus();
  // Use window?.dataLayer to handle cases when dataLayer is not available
  window?.dataLayer?.push({
    event: "addToCart",
    ecommerce: {
      currencyCode: "USD",
      add: {
        products: [
          {
            name: hotel.name, //`${hotel.name} -- ${room.Name}`,
            hotelId: hotel.crs_code,
            variant: room.name,
            price: price,
            brand: brandName,
            category: rate?.rateCode,
            quantity: totalNights,
          },
        ],
      },
      city: hotel?.address?.locality,
      rooms: totalRooms,
    },
    loggedState: loggedState,
  });
};

export const checkoutStep1GTMDataLayer = async (
  hotel: Hotel,
  rooms: Room[],
  checkin: string,
  checkout: string
): Promise<void> => {
  const totalNights = differenceInDays(new Date(checkout), new Date(checkin));
  const brandName =
    hotel.relationships &&
    hotel.relationships.brand_id &&
    hotel.relationships.brand_id.name;
  const products: Product[] = [];
  const loggedState = getLoggedStatus();
  const { email, profileType } = getLoggedUserInfo();
  let emailHash = "";
  if (email) {
    emailHash = await sha256(email);
  }
  rooms.forEach(function (r) {
    const rate = r.rate;
    r.room &&
      rate &&
      products.push({
        name: r.room.name,
        price: rate.subTotal,
        brand: brandName,
        hotelId: hotel.crs_code,
        hotelName: hotel.name,
        category: rate.rateCode,
        rateName: rate.name,
        quantity: totalNights,
        ratecount: r.room.RateCount,
      });
  });

  // Use window?.dataLayer to handle cases when dataLayer is not available
  window?.dataLayer?.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: { step: 1, option: "All" },
        products: products,
      },
      rooms: rooms?.length,
      city: hotel?.address?.locality,
      ...(email && { emailHash: emailHash }),
      ...(profileType && { profileType: profileType }),
    },
    loggedState: loggedState,
  });
};

export const checkoutStep2GTMDataLayer = async (
  hotel: Hotel,
  rooms: Room[],
  checkin: string,
  checkout: string,
  payment: Payment | null
): Promise<void> => {
  const totalNights = differenceInDays(new Date(checkout), new Date(checkin));
  const brandName =
    hotel.relationships &&
    hotel.relationships.brand_id &&
    hotel.relationships.brand_id.name;
  const products: Product[] = []; // Adjust 'any[]' to match the actual type of the 'products' array if possible
  const applyBucks = payment ? !!payment.with_giftcards : false;
  let bucksAmount = payment ? parseFloat(payment.giftcard_amount) : 0;
  const loggedState = getLoggedStatus();
  const totalRooms = rooms?.length;
  const { email, profileType } = getLoggedUserInfo();
  let emailHash = "";
  if (email) {
    emailHash = await sha256(email); // Ensure that email is a string before passing it to sha256 function
  }
  rooms.forEach(function (r) {
    const rate: any = r.rate;
    if (rate && rate.subTotal) {
      let roomCost = parseFloat(rate?.subTotal);
      if (applyBucks && bucksAmount > 0 && roomCost > 0) {
        if (bucksAmount <= roomCost) {
          roomCost = roomCost - bucksAmount;
          bucksAmount = 0;
        } else {
          bucksAmount = bucksAmount - roomCost;
          roomCost = 0;
        }
      }
      r.room &&
        products.push({
          name: r.room.name,
          price: roomCost,
          brand: brandName,
          hotelId: hotel.crs_code,
          hotelName: hotel.name,
          category: rate.rateCode,
          rateName: rate.name,
          quantity: totalNights,
        });
    }
  });

  // Use window?.dataLayer to handle cases when dataLayer is not available
  window?.dataLayer?.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: { step: 2, option: "All" },
        products: products,
      },
      rooms: totalRooms,
      city: hotel?.address?.locality,
      ...(email && { emailHash: emailHash }),
      ...(profileType && { profileType: profileType }),
    },
    loggedState: loggedState,
  });
};

export const editResConfirmationGTMDataLayer = async (
  res: Reservation[] | null,
  hotel: Hotel,
  hotelBrand: HotelBrand | null,
  details: Details,
  confirmationType?: string
): Promise<void> => {
  const loggedState = getLoggedStatus();
  const { email, profileType } = getLoggedUserInfo();
  let emailHash = "";
  if (email) {
    emailHash = await sha256(email);
  }
  if (res) {
    const resDetail = res[0].details;
    const address = resDetail.hotelAddress;
    window.rlPAGENAME = "reservation-confirmation";
    window.rlPROPERTYCODE = resDetail.hotel.id;
    window.rlPROPERTYNAME = hotel.name;
    window.rlCITY = address.cityName;
    window.rlSTATE = address.region;
    window.rlZIPCODE = address.postalCode;
    window.rlCOUNTRY = address.country;
    window.rlCURRENCYCODE = "USD";

    const numNights = differenceInDays(
      new Date(resDetail.End),
      new Date(resDetail.Start)
    );
    let roomTotal = 0,
      totalTax = 0,
      totalUpgrades = 0;
    localStorage["gtmLastReservationId"] = res[0].crs_reservation_id;

    let adults = 0;
    let children = 0;
    let totalGuests = 0;
    res.forEach((r, rIndex) => {
      const rDetail = r.details;
      const payment = {
        Subtotal: 0,
        Upgrades: 0,
        Taxes: 0,
        Total: 0,
      };
      rDetail.roomRates.forEach((item) => {
        payment.Subtotal += item.subTotal
          ? parseFloat(item.subTotal)
          : item.Subtotal
          ? parseFloat(item.Subtotal)
          : 0;
        payment.Upgrades += item.upgrades
          ? parseFloat(item.upgrades)
          : item.Upgrades
          ? parseFloat(item.Upgrades)
          : 0;
        payment.Taxes += item.taxes
          ? parseFloat(item.taxes)
          : item.Taxes
          ? parseFloat(item.Taxes)
          : 0;
        payment.Total += item.total
          ? parseFloat(item.total)
          : item.Total
          ? parseFloat(item.Total)
          : 0;
      });
      adults = adults + +r.details.count.adult;
      if (r.details.count.child) {
        children = children + +r.details.count.child;
      }
      totalGuests = adults + children;

      window["rlCONFIRMATIONNUMBER_" + rIndex] = r.crs_reservation_id;

      window["rlARRIVAL_" + rIndex] = rDetail.Start;
      window["rlDEPARTURE_" + rIndex] = rDetail.End;
      window["rlRATEPLAN_" + rIndex] = rDetail.ratePlanCode;
      window["rlROOMTYPE_" + rIndex] = rDetail.roomTypeCode;
      window["rlROOMNAME_" + rIndex] =
        rDetail.roomDescription && rDetail.roomDescription.name;

      window["rlNUMNIGHTS_" + rIndex] = numNights;
      window["rlADULTS_" + rIndex] = rDetail.count.adult;
      window["rlCHILDREN_" + rIndex] = rDetail.count.child || 0;
      //Dev Check
      window["rlSELECTEDRATETYPE_" + rIndex] = details?.isonlyRate
        ? "Only Rate Displayed"
        : details?.lowestRate === details?.rateCode
        ? "Lowest Rate"
        : "Not the Lowest Rate";
      window["rlRATECOUNT_" + rIndex] = details.rateCount;
      window["rlROOMRATE_" + rIndex] = payment.Subtotal;
      window["rlADDONVALUE_" + rIndex] = payment.Upgrades;
      window["rlTAX_" + rIndex] = payment.Taxes;
      window["rlTOTALRATEWITHTAXESADDON_" + rIndex] = payment.Total;
      window["rlADR_" + rIndex] = parseFloat(
        payment.Subtotal / numNights
      ).toFixed(2);

      roomTotal += parseFloat(payment.Subtotal);
      totalTax += parseFloat(payment.Taxes);
      totalUpgrades += parseFloat(payment.Upgrades);
    });

    window.rlNUMROOMS = res.length;

    window.rlTOTALNUMNIGHTS = numNights * window.rlNUMROOMS;
    window.rlTOTALROOMCOST = roomTotal;
    window.rlTOTALTAX = totalTax;
    window.rlTOTALADDON = totalUpgrades;
    window.rlTOTALCOSTWITHTAXESADDON = roomTotal + totalTax + totalUpgrades;
    window.rlTOTALROOMADDONS = roomTotal + totalUpgrades;
    window.rlTOTALADR = parseFloat(
      window.rlTOTALROOMCOST / window.rlTOTALNUMNIGHTS
    ).toFixed(2);

    if (process.env.NODE_ENV === "production" && typeof gtag !== "undefined") {
      // gtag('event', 'conversion', { 'send_to': 'AW-1010172688/8DxfCKrX0GkQkIbY4QM',
      //   'value': window.rlTOTALROOMADDONS,
      //   'currency': window.rlCURRENCYCODE,
      //   'transaction_id': window.rlCONFIRMATIONNUMBER_0
      // });
    }

    const products: Product[] = [];

    for (let i = 0; i < window.rlNUMROOMS; i++) {
      const product: any = {};
      product.name = window["rlPROPERTYNAME"];
      product.hotel_code = hotel.crs_code || window["rlPROPERTYCODE"];
      product.id = window["rlCONFIRMATIONNUMBER_" + i];
      product.sku = window["rlCONFIRMATIONNUMBER_" + i];
      product.category = window["rlRATEPLAN_" + i];
      product.brand = hotelBrand?.name;
      product.brand_code = hotelBrand?.code;
      product.price = window["rlROOMRATE_" + i] / window["rlNUMNIGHTS_" + i];
      product.quantity = window["rlNUMNIGHTS_" + i];
      product.selectedratetype = window["rlSELECTEDRATETYPE_" + i];
      product.ratecount = window["rlRATECOUNT_" + i];
      products.push(product);
    }
    window.dataLayer &&
      window.dataLayer.push({
        ecommerce: {
          currencyCode: window.rlCURRENCYCODE,
          purchase: {
            actionField: {
              id: window.rlCONFIRMATIONNUMBER_0,
              affiliation: "Sonesta Hotels",
              revenue: window.rlTOTALROOMADDONS,
              tax: window.rlTOTALTAX,
            },
            products: products,
          },
          checkinDate: details.checkinDate,
          checkoutDate: details.checkoutDate,
          lengthOfStay: details.lengthOfStay,
          adults: adults,
          children: children,
          totalGuests: totalGuests,
          city: window.rlCITY,
          rooms: window.rlNUMROOMS,
          rateName: details.rateName,
          ...(email && { emailHash: emailHash }),
          ...(profileType && { profileType: profileType }),
          reservationType: confirmationType,
        },
        loggedState: loggedState,
        event: "confirmation",
      });
  }
};

export const resConfirmationGTMDataLayer = async (
  res: Reservation[] | null,
  hotel: Hotel,
  hotelBrand: HotelBrand | null,
  details: Details,
  confirmationType?: string,
  isWebFramed: boolean
): Promise<void> => {
  let isNewBooking = true;
  const loggedState = getLoggedStatus();
  const { email, profileType } = getLoggedUserInfo();
  let emailHash = "";
  if (email) {
    emailHash = await sha256(email);
  }
  if (res) {
    // check if it's new booking or just page refresh
    if (localStorage["gtmLastReservationId"]) {
      res.forEach((r) => {
        if (r.crs_reservation_id === localStorage["gtmLastReservationId"]) {
          isNewBooking = false;
        }
      });
    }
    // set gtm globals only if it's a new booking.
    if (isNewBooking) {
      const resDetail = res[0].details;
      const address = resDetail.hotelAddress;
      window.rlPAGENAME = "reservation-confirmation";
      window.rlPROPERTYCODE = resDetail.hotel.id;
      window.rlPROPERTYNAME = hotel.name;
      window.rlCITY = address.cityName;
      window.rlSTATE = address.region;
      window.rlZIPCODE = address.postalCode;
      window.rlCOUNTRY = address.country;
      window.rlCURRENCYCODE = "USD";

      const numNights = differenceInDays(
        new Date(resDetail.End),
        new Date(resDetail.Start)
      );
      let roomTotal = 0,
        totalTax = 0,
        totalUpgrades = 0;
      localStorage["gtmLastReservationId"] = res[0].crs_reservation_id;

      let adults = 0;
      let children = 0;
      let totalGuests = 0;
      res.forEach((r, rIndex) => {
        const rDetail = r.details;
        const payment = {
          Subtotal: 0,
          Upgrades: 0,
          Taxes: 0,
          Total: 0,
        };
        rDetail.roomRates.forEach((item) => {
          payment.Subtotal += item.subTotal
            ? parseFloat(item.subTotal)
            : item.Subtotal
            ? parseFloat(item.Subtotal)
            : 0;
          payment.Upgrades += item.upgrades
            ? parseFloat(item.upgrades)
            : item.Upgrades
            ? parseFloat(item.Upgrades)
            : 0;
          payment.Taxes += item.taxes
            ? parseFloat(item.taxes)
            : item.Taxes
            ? parseFloat(item.Taxes)
            : 0;
          payment.Total += item.total
            ? parseFloat(item.total)
            : item.Total
            ? parseFloat(item.Total)
            : 0;
        });
        adults = adults + +r.details.count.adult;
        if (r.details.count.child) {
          children = children + +r.details.count.child;
        }
        totalGuests = adults + children;

        window["rlCONFIRMATIONNUMBER_" + rIndex] = r.crs_reservation_id;

        window["rlARRIVAL_" + rIndex] = rDetail.Start;
        window["rlDEPARTURE_" + rIndex] = rDetail.End;
        window["rlRATEPLAN_" + rIndex] = rDetail.ratePlanCode;
        window["rlROOMTYPE_" + rIndex] = rDetail.roomTypeCode;
        window["rlROOMNAME_" + rIndex] =
          rDetail.roomDescription && rDetail.roomDescription.name;

        window["rlNUMNIGHTS_" + rIndex] = numNights;
        window["rlADULTS_" + rIndex] = rDetail.count.adult;
        window["rlCHILDREN_" + rIndex] = rDetail.count.child || 0;
        //Dev Check
        window["rlSELECTEDRATETYPE_" + rIndex] = details?.isonlyRate
          ? "Only Rate Displayed"
          : details?.lowestRate === details?.rateCode
          ? "Lowest Rate"
          : "Not the Lowest Rate";
        window["rlRATECOUNT_" + rIndex] = details.rateCount;
        window["rlROOMRATE_" + rIndex] = payment.Subtotal;
        window["rlADDONVALUE_" + rIndex] = payment.Upgrades;
        window["rlTAX_" + rIndex] = payment.Taxes;
        window["rlTOTALRATEWITHTAXESADDON_" + rIndex] = payment.Total;
        window["rlADR_" + rIndex] = parseFloat(
          payment.Subtotal / numNights
        ).toFixed(2);

        roomTotal += parseFloat(payment.Subtotal);
        totalTax += parseFloat(payment.Taxes);
        totalUpgrades += parseFloat(payment.Upgrades);
      });

      window.rlNUMROOMS = res.length;

      window.rlTOTALNUMNIGHTS = numNights * window.rlNUMROOMS;
      window.rlTOTALROOMCOST = roomTotal;
      window.rlTOTALTAX = totalTax;
      window.rlTOTALADDON = totalUpgrades;
      window.rlTOTALCOSTWITHTAXESADDON = roomTotal + totalTax + totalUpgrades;
      window.rlTOTALROOMADDONS = roomTotal + totalUpgrades;
      window.rlTOTALADR = parseFloat(
        window.rlTOTALROOMCOST / window.rlTOTALNUMNIGHTS
      ).toFixed(2);

      if (
        process.env.NODE_ENV === "production" &&
        typeof gtag !== "undefined"
      ) {
        // gtag('event', 'conversion', { 'send_to': 'AW-1010172688/8DxfCKrX0GkQkIbY4QM',
        //   'value': window.rlTOTALROOMADDONS,
        //   'currency': window.rlCURRENCYCODE,
        //   'transaction_id': window.rlCONFIRMATIONNUMBER_0
        // });
      }

      const products: Product[] = [];

      for (let i = 0; i < window.rlNUMROOMS; i++) {
        const product: any = {};
        product.name = window["rlPROPERTYNAME"];
        product.hotel_code = hotel.crs_code || window["rlPROPERTYCODE"];
        product.id = window["rlCONFIRMATIONNUMBER_" + i];
        product.sku = window["rlCONFIRMATIONNUMBER_" + i];
        product.category = window["rlRATEPLAN_" + i];
        product.brand = hotelBrand?.name;
        product.brand_code = hotelBrand?.code;
        product.price = window["rlROOMRATE_" + i] / window["rlNUMNIGHTS_" + i];
        product.quantity = window["rlNUMNIGHTS_" + i];
        product.selectedratetype = window["rlSELECTEDRATETYPE_" + i];
        product.ratecount = window["rlRATECOUNT_" + i];
        products.push(product);
      }
      window.dataLayer &&
        window.dataLayer.push({
          ecommerce: {
            currencyCode: window.rlCURRENCYCODE,
            purchase: {
              actionField: {
                id: window.rlCONFIRMATIONNUMBER_0,
                affiliation: "Sonesta Hotels",
                revenue: window.rlTOTALROOMADDONS,
                tax: window.rlTOTALTAX,
              },
              products: products,
            },
            checkinDate: details.checkinDate,
            checkoutDate: details.checkoutDate,
            lengthOfStay: details.lengthOfStay,
            ...(!isWebFramed && { email: details.resEmail }),
            adults: adults,
            children: children,
            totalGuests: totalGuests,
            city: window.rlCITY,
            rooms: window.rlNUMROOMS,
            rateName: details.rateName,
            ...(email && { emailHash: emailHash }),
            ...(profileType && { profileType: profileType }),
            reservationType: confirmationType,
          },
          loggedState: loggedState,
          event: "confirmation",
        });
    }
  }
};

export const addPropertyRoomsGTMDataLayer = (
  hotel: Hotel,
  hotelRooms: Room[],
  search: Search,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  hotelCheckout: any
): void => {
  const brandName =
    hotel.relationships &&
    hotel.relationships.brand_id &&
    hotel.relationships.brand_id.name;
  const loggedState = getLoggedStatus();
  const {
    checkin,
    checkout,
    discount,
    distance,
    groupCode,
    promotionCode,
    searchString,
    rooms,
  } = search;

  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  const totalRooms = search?.rooms?.length;
  hotelRooms.forEach((room: any) => {
    let isTPRNR = false;
    room?.Rates?.forEach((element: any) => {
      if (element.RateCode && TP_REDEMPTION_RATE?.includes(element.RateCode))
        isTPRNR = true;
    });
    const eventName = room.quantity > 0 ? "AvailableRoom" : "SoldoutRoom";
    window.dataLayer.push({
      event: eventName,
      ecommerce: {
        hotel: hotel.name,
        hotelId: hotel.crs_code,
        brand: brandName,
        rooms: totalRooms,
        city: hotel?.address?.locality,
        search: {
          searchString: searchString,
          checkin: checkin,
          checkout: checkout,
          rooms: rooms,
          discount: discount,
          distance: distance,
          groupCode: groupCode,
          promotionCode: promotionCode,
        },
        roomName: room.name,
        isUserCanRedeemThisRoom: isTPRNR && hotelCheckout?.isEligible,
        requiredPointsForRedemption: hotelCheckout?.requiredPoints || 0,
        isTPRNR,
      },
      loggedState: loggedState,
    });
  });
};

export const addHotelsAvailabilityGTMDataLayer = (
  hotel: Hotel,
  search: Search,
  soldout: boolean
): void => {
  const {
    checkin,
    checkout,
    discount,
    distance,
    groupCode,
    promotionCode,
    searchString,
    rooms,
    amenities,
    brands,
  } = search;
  const loggedState = getLoggedStatus();

  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  const eventName = soldout ? "SoldOutProperty" : "AvailableProperty";
  window.dataLayer.push({
    event: eventName,
    ecommerce: {
      search: {
        searchString: searchString,
        checkin: checkin,
        checkout: checkout,
        rooms: rooms,
        discount: discount,
        distance: distance,
        groupCode: groupCode,
        promotionCode: promotionCode,
        amenitiesFilter: amenities,
        brandFilter: brands,
      },
      name: hotel.name,
      hotelId: hotel.crsHotelCode,
      brand: hotel.brand?.name,
    },
    loggedState: loggedState,
  });
};

export const addProductImpressionGTMDataLayer = (
  hotelList: Hotel[],
  search: Search
): void => {
  const products: Product[] = [];
  const loggedState = getLoggedStatus();

  hotelList.forEach((hotel, i) => {
    products.push({
      name: hotel.name,
      hotelId: hotel.crsHotelCode,
      list: search.searchString,
      category: null,
      brand: hotel.brand?.name,
      price: null,
      position: i + 1,
    });
  });
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push({
    event: "product list impressions",
    ecommerce: {
      currencyCode: "USD",
      impressions: products,
      rooms: search?.rooms?.length,
    },
    loggedState: loggedState,
  });
};

export const pushErrorInGA = (
  errorType: string,
  errorSubtype: string,
  message: string
): void => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  const loggedState = getLoggedStatus();
  const data = {
    eventCategory: errorType,
    eventAction: errorSubtype,
    eventLabel: message,
    loggedState: loggedState,
  };
  window.dataLayer.push({
    event: "websiteError",
    ...data,
  });
  /* eslint-disable no-undef */
  typeof ga !== "undefined" &&
    ga("send", {
      hitType: "event",
      ...data,
    });
  /* eslint-disable no-undef */
};

export const sendLoginStateInGA = (
  loggedIn: boolean | undefined,
  pageUrl: string,
  userData: any
): void => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  const loggedState =
    typeof loggedIn === "undefined"
      ? getLoggedStatus()
      : loggedIn
      ? "Logged In"
      : "Logged Out";
  const data = {
    eventCategory: "CaptureLoggedState",
    eventLabel: loggedState,
    pageUrl: pageUrl,
    memberId: userData?.memberId,
    profileType: userData?.profileType,
    upcomingTrip: userData?.upcomingTrip,
    daysLeftForUpcomingTrip: userData?.daysLeftForUpcomingTrip,
    hotelIdOfLastStay: userData?.hotelIdOfLastStay,
    rewardPoints: userData?.rewardPoints,
  };

  window.dataLayer.push({
    event: "CaptureLoggedState",
    ...data,
  });
  /* eslint-disable no-undef */
  if (typeof ga !== "undefined") {
    ga("set", "dimension9", loggedState);
    ga("send", {
      hitType: "event",
      ...data,
    });
  }
  /* eslint-disable no-undef */
};

export const addPageTypeInGTMDataLayer = (pageType: string): void => {
  try {
    let epsilonPageType: string, promoId: number | string;
    switch (pageType) {
      case "home":
        epsilonPageType = "Homepage";
        promoId = 1;
        break;
      case "landing page":
      case "brand page":
        epsilonPageType = "Information Pages";
        promoId = 2;
        break;
      case "search":
        epsilonPageType = "Search Results";
        promoId = 7;
        break;
      case "hotel-detail":
        epsilonPageType = "Hotel Details";
        promoId = 4;
        break;
      case "checkout select room":
      case "checkout guest details":
      case "checkout payment details":
        epsilonPageType = "Cart";
        promoId = 5;
        break;
      case "reservation confirmation":
        epsilonPageType = "Conversion Confirmation";
        promoId = "";
        break;
      case "sign up":
        epsilonPageType = "Account Signup";
        promoId = 941;
        break;
      case "account":
      case "reservation":
      case "edit reservation":
        epsilonPageType = "Account Center Pages";
        promoId = 942;
        break;
      default:
        epsilonPageType = pageType;
    }
    window.dataLayer = window.dataLayer || [];
    const loggedState = getLoggedStatus();
    // sendLoginStateInGA();
    window.dataLayer.push({
      pageType: pageType,
      loggedState: loggedState,
      Page: epsilonPageType,
      PromoId: promoId,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};
export const ContactUsFormGTMDataLayer = (
  topic: string,
  hotelName: string,
  isMobile: boolean
): void => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    event: "ContactUs",
    source: isMobile ? "Mobile Web" : "Desktop Site",
    topic,
    hotelName,
  });
};

export const fireProductDetailEvent = (
  product: Product,
  searchString: string
): void => {
  const loggedState = getLoggedStatus();
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "productDetail",
    ecommerce: {
      detail: {
        actionField: { list: searchString },
        products: [product],
      },
    },
    loggedState: loggedState,
  });
};

export const norIntegrationOfferEvent = (
  reservationID: string,
  upgradeOffered: boolean
): void => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    event: "Nor1UpgradeOffer",
    upgradeOffered: upgradeOffered,
    reservationID: reservationID,
  });
};

export const norIntegrationOfferClickEvent = (reservationID: string): void => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    event: "Nor1UpgradeClick",
    upgradeClicked: true,
    reservationID: reservationID,
  });
};

export const travelPassEventsGTMDataLayer = (
  status: string,
  source: string
): void => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    event: "Enrollment",
    status: status,
    source: source,
  });
};
