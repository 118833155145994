import { IApolloClientProps } from "./ApolloBaseClient";
import { createApolloClient } from "./ApolloClient";
import { TokenProvider } from "../TokenProvider";
import {
  GET_HOTEL_AVAILABILITY,
  GET_HOTEL_DETAILS,
  GET_HOTEL_PROXIMITY,
  GET_LOCATION_PROXIMITY,
  GUEST_LOGIN_QUERY,
  SEND_RESET_PASSWORD_EMAIL_MUTATION,
  SIGN_UP_MUTATION,
  SIGN_UP_WIFI_MUTATION,
  CHECK_USER_BY_EMAIL_QUERY,
  RESET_PASSWORD_MUTATION,
  SEARCH_HOTEL_BY_INTEREST,
  CANCEL_RESERVATION_MUTATION,
  GET_RESERVATION_DETAILS_QUERY,
  BRAND_FILTER_QUERY,
  GET_RECENT_SEARCH_QUERY,
  POST_RECENT_SEARCH_QUERY,
  SEARCH_HOTELS_AMENITIES_QUERY,
  COMPLETE_SIGN_UP_PROCESS_MUTATION,
  GET_LOCATION_INTERESTS,
} from "./GuestQuery";
import { ICompleteSignUpProcessRequestInput } from "../@types/RequestTypes";
import {
  AuthQuery,
  CancelReservationMutation,
  CompleteSignUpProcessMutation,
  Get_Recent_SearchQuery,
  GetHotelAvailabilityQuery,
  GetHotelContactsQuery,
  GetReservationQuery,
  GetUserByEmailQuery,
  HotelQuery,
  HotelsQuery,
  Post_Recent_SearchMutation,
  ResetPassword_SendEmailMutation,
  ResetPasswordMutation,
  SearchHotelsAmenitiesByHotelCodesQuery,
  SearchHotelsBrandsByHotelCodesQuery,
  SearchHotelsByInterestQuery,
  SearchLocations_ProximityQuery,
  SignUp_WifiMutation,
  SignUpMutation,
  GetLocationInterestsQuery,
} from "../../__generated__/types-guest";

export class GuestClient {
  public client: any;
  public token: any;

  constructor(params: IApolloClientProps) {
    this.client = createApolloClient(params);
  }

  public async guestLogin(
    username: string,
    password: string
  ): Promise<AuthQuery> {
    const result: AuthQuery = await this.client.getData(
      {
        query: GUEST_LOGIN_QUERY,
        variables: {
          username: username,
          password: password,
        },
      },
      this.token
    );
    return result;
  }

  public async sendResetPasswordEmail(
    email: string
  ): Promise<ResetPassword_SendEmailMutation> {
    const result: ResetPassword_SendEmailMutation = await this.client.postData(
      {
        mutation: SEND_RESET_PASSWORD_EMAIL_MUTATION,
        variables: {
          email: email,
        },
      },
      this.token
    );
    return result;
  }

  public async resetPassword(
    newPassword: string,
    args: string
  ): Promise<ResetPasswordMutation> {
    const result: ResetPasswordMutation = await this.client.postData(
      {
        mutation: RESET_PASSWORD_MUTATION,
        variables: {
          newPassword: newPassword,
          args: args,
        },
      },
      this.token
    );
    return result;
  }

  public async completeSignUpProcess(
    requestInput: ICompleteSignUpProcessRequestInput
  ): Promise<CompleteSignUpProcessMutation> {
    const result: CompleteSignUpProcessMutation = await this.client.postData(
      {
        mutation: COMPLETE_SIGN_UP_PROCESS_MUTATION,
        variables: { updateUserProfileRequest: requestInput },
      },
      this.token
    );
    return result;
  }

  public async signUp(
    email: string,
    firstName: string,
    lastName: string
  ): Promise<SignUpMutation> {
    const result: SignUpMutation = await this.client.postData(
      {
        mutation: SIGN_UP_MUTATION,
        variables: {
          email: email,
          firstName: firstName,
          lastName: lastName,
        },
      },
      this.token
    );
    return result;
  }

  public async signUpWifi(
    email: string,
    firstName: string,
    lastName: string,
    hotelName: string
  ): Promise<SignUp_WifiMutation> {
    const result: SignUp_WifiMutation = await this.client.postData(
      {
        mutation: SIGN_UP_WIFI_MUTATION,
        variables: {
          email: email,
          firstName: firstName,
          lastName: lastName,
          hotelName: hotelName,
        },
      },
      this.token
    );
    return result;
  }

  public async checkUserByEmail(email: string): Promise<GetUserByEmailQuery> {
    const result: GetUserByEmailQuery = await this.client.getData(
      {
        query: CHECK_USER_BY_EMAIL_QUERY,
        variables: {
          email: email,
        },
      },
      this.token
    );
    return result;
  }

  public async getHotelDetails(hotelID: string): Promise<HotelQuery> {
    const result: HotelQuery = await this.client.getData(
      {
        query: GET_HOTEL_DETAILS,
        variables: {
          id: hotelID,
        },
      },
      this.token,
      false,
      null,
      false,
      true
    );
    return result;
  }

  public async getHotelAvailability(
    adults: number,
    children: number,
    childrenAges: Array<string>,
    crsHotelCode: string,
    chainId: string,
    start: string,
    end: string,
    availReqType: string,
    promotionCode: string,
    ratePlanCode: string,
    ratePlanFilterCode: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    abortController: any
  ): Promise<GetHotelAvailabilityQuery> {
    const formatChildAge = childrenAges
      ? childrenAges.map((age) => {
          if (age === "") {
            return 0; // Convert empty string to 0
          } else {
            return Number(age); // Convert string to number
          }
        })
      : [];
    const result: GetHotelAvailabilityQuery = await this.client.getData(
      {
        query: GET_HOTEL_AVAILABILITY,
        variables: {
          input: {
            adults: adults,
            children: children,
            childrenAges: formatChildAge,
            crsHotelCode: crsHotelCode,
            chainId: chainId,
            quantity: 1,
            start: start,
            end: end,
            availReqType: availReqType,
            promotionCode: promotionCode ? promotionCode.toUpperCase() : "",
            ratePlanCode: ratePlanCode ? ratePlanCode.toUpperCase() : "",
            ratePlanFilterCode: ratePlanFilterCode,
          },
        },
      },
      this.token,
      false,
      abortController,
      true,
      true
    );
    return result;
  }

  public async getHotelProximity(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    distance: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    latitude: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    longitude: any,
    options?: {
      type?: any;
      cityName?: any;
    }
  ): Promise<HotelsQuery> {
    const result: HotelsQuery = await this.client.getData(
      {
        query: GET_HOTEL_PROXIMITY,
        variables: {
          proximity: {
            rangeInMilesFromOrigin: distance,
            type: options?.type,
            cityName: options?.cityName,
            coordinates: {
              latitude: latitude,
              longitude: longitude,
            },
          },
        },
      },
      this.token
    );
    return result;
  }
  public async getHotelByInterest(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    distance: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    latitude: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    longitude: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    url: any
  ): Promise<SearchHotelsByInterestQuery> {
    const result: SearchHotelsByInterestQuery = await this.client.getData(
      {
        query: SEARCH_HOTEL_BY_INTEREST,
        variables: {
          proximity: {
            rangeInMilesFromOrigin: distance,
            coordinates: {
              latitude: latitude,
              longitude: longitude,
            },
            url: url,
          },
        },
      },
      this.token
    );
    return result;
  }

  public async brandFilterHotels(
    hotelCodes: string
  ): Promise<SearchHotelsBrandsByHotelCodesQuery> {
    const result: SearchHotelsBrandsByHotelCodesQuery =
      await this.client.getData(
        {
          query: BRAND_FILTER_QUERY,
          variables: {
            hotelCrsCodes: hotelCodes,
          },
        },
        this.token
      );
    return result;
  }
  public async searchHotelsAmenities(
    hotelCodes: string
  ): Promise<SearchHotelsAmenitiesByHotelCodesQuery> {
    const result: SearchHotelsAmenitiesByHotelCodesQuery =
      await this.client.getData(
        {
          query: SEARCH_HOTELS_AMENITIES_QUERY,
          variables: {
            hotelCodes: hotelCodes,
          },
        },
        this.token
      );
    return result;
  }

  public async getLocationInterests(
    locationSearchInputUrl: string
  ): Promise<GetLocationInterestsQuery> {
    const result: GetLocationInterestsQuery = await this.client.getData(
      {
        query: GET_LOCATION_INTERESTS,
        variables: {
          locationSearchInputUrl: locationSearchInputUrl,
        },
      },
      this.token
    );
    return result;
  }

  public async getLocationProximity(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    distance: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    latitude: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    longitude: any
  ): Promise<SearchLocations_ProximityQuery> {
    const result: SearchLocations_ProximityQuery = await this.client.getData(
      {
        query: GET_LOCATION_PROXIMITY,
        variables: {
          input: {
            byProximity: {
              rangeInMilesFromOrigin: distance,
              coordinates: {
                latitude: latitude,
                longitude: longitude,
              },
            },
          },
        },
      },
      this.token
    );
    return result;
  }

  public async cancelReservation(
    confirmationNumber: string
  ): Promise<CancelReservationMutation> {
    const result: CancelReservationMutation = await this.client.postData(
      {
        mutation: CANCEL_RESERVATION_MUTATION,
        variables: {
          confirmNumber: confirmationNumber,
        },
      },
      this.token,
      false,
      null,
      false,
      true
    );
    return result;
  }

  public async getReservationDetails(
    confirmationNumber: string,
    surname: string
  ): Promise<GetReservationQuery> {
    const result: GetReservationQuery = await this.client.getData(
      {
        query: GET_RESERVATION_DETAILS_QUERY,
        variables: {
          confirmationNumber: confirmationNumber,
          surname: surname,
        },
      },
      this.token,
      false,
      null,
      true,
      true
    );
    return result;
  }

  public async getRecentSearch(
    memberId: string
  ): Promise<Get_Recent_SearchQuery> {
    const result: Get_Recent_SearchQuery = await this.client.getData(
      {
        query: GET_RECENT_SEARCH_QUERY,
        variables: { memberId: memberId },
      },
      this.token,
      false,
      null,
      true
    );
    return result;
  }

  public async postRecentSearch(
    memberID: string,
    startDate: string,
    hotelID: string,
    endDate: string,
    roomType: string,
    promoCode: string,
    occupancy: string,
    groupCode: string,
    ratePlanFilterCode: string,
    lastUpdated: string
  ): Promise<Post_Recent_SearchMutation> {
    const result: Post_Recent_SearchMutation = await this.client.postData(
      {
        mutation: POST_RECENT_SEARCH_QUERY,
        variables: {
          memberID: `${memberID}`,
          startDate: startDate,
          hotelID: hotelID,
          endDate: endDate,
          roomType: roomType,
          promoCode: promoCode,
          occupancy: occupancy,
          groupCode: groupCode,
          ratePlanFilterCode: ratePlanFilterCode,
          lastUpdated: lastUpdated,
        },
      },
      this.token,
      false,
      null,
      true
    );
    return result;
  }
}

export const createGuestClient = async (
  params: IApolloClientProps
): Promise<GuestClient> => {
  const client = new GuestClient(params);
  client.token = await new TokenProvider().getAnonToken();
  return client;
};
