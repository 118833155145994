import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Storage } from "../../../utils/storage";
import { AppDispatch, AppThunk, RootState } from "../../store";
// import IProfile from '../../../@types/IProfile';
// import { auth } from '../../../utils/auth';
import { getMemberProfile } from "../../../services/loyalty";
import { memberServiceProvider } from "../../../services/ServiceProvider";
// import { updateLoginTime, revokeToken } from '../../../services/crm';
import moment from "moment-mini";
import { cancelRedemtionReservation } from "../../../services/crs";
import { convertArrayToObject } from "../../../services/helpers";
import { getMemberPoints } from "../../../services/redemption";
import { sendLoginStateInGA } from "../../../utils/datalayers";
import { addHiddenIframe, getUserTripsDataforGA } from "../../../utils/helpers";
import { clearRecentSearch } from "../RecentSearch/RecentSearch";

type TReuestStatus = {
  type?: string;
  status?: string;
  cancelID?: string;
};
export interface IMemberState {
  isLoggedIn?: boolean;
  profile?: any;
  crmProfile?: any;
  upcomingReservations?: Array<any>;
  recentStays?: Array<any>;
  cancelledReservations?: Array<any>;
  error?: any;
  updateRequestStatus?: TReuestStatus;
  profileModal?: boolean;
}
const initialState = {
  isLoggedIn: false,
  profile: {},
  crmProfile: {},
  upcomingReservations: [],
  recentStays: [],
  cancelledReservations: [],
  error: null,
  updateRequestStatus: {
    type: null,
    status: null,
    cancelID: null,
  },
  profileModal: false,
  memberPointsTally: 0,
};

const memberSlice = createSlice({
  name: "member",
  initialState: initialState,
  reducers: {
    setCRMProfile: (state, { payload }: PayloadAction<any>) => {
      state.crmProfile = payload;
      return state;
    },
    setMember: (state, { payload }: PayloadAction<any>) => {
      state.isLoggedIn = payload.isLoggedIn;
      state.profile = payload.profile;
      return state;
    },
    clearMember: (state) => {
      state = initialState;
      Storage.ClearLocalStorageValue("isLoggedIn");
      Storage.ClearLocalStorageValue("auth-token");
      return state;
    },
    setError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      return state;
    },
    clearError: (state) => {
      state.error = null;
      return state;
    },
    updateRequestStatus: (state, { payload }: PayloadAction<any>) => {
      state.updateRequestStatus = payload;
      return state;
    },
    clearUpdateRequestStatus: (state) => {
      state.updateRequestStatus = {
        type: null,
        status: null,
        cancelID: null,
      };
      return state;
    },
    setUpcomingRes: (state, { payload }: PayloadAction<any>) => {
      state.upcomingReservations = payload;
      return state;
    },
    setRecentStays: (state, { payload }: PayloadAction<any>) => {
      state.recentStays = payload;
      return state;
    },
    setCancelledRes: (state, { payload }: PayloadAction<any>) => {
      state.cancelledReservations = payload;
      return state;
    },
    setMemberPointsTally: (state, { payload }: PayloadAction<any>) => {
      state.memberPointsTally = payload;
      return state;
    },
  },
});

export const memberReducer = memberSlice.reducer;
export const {
  setMember,
  setCRMProfile,
  clearError,
  setUpcomingRes,
  setRecentStays,
  setCancelledRes,
  clearMember,
  updateRequestStatus,
  clearUpdateRequestStatus,
  setMemberPointsTally,
} = memberSlice.actions;

//Async actions

export const login =
  (data?: any | undefined): AppThunk =>
  async (dispatch: AppDispatch) => {
    // await auth.handleAuthentication(data);
    const guest = {
      firstName: data.givenName,
      lastName: data.familyName,
      email: data.email,
      // sessionToken: data.sessionToken,
    };
    // updateLoginTime(data.user.profile.login);
    dispatch(setMember({ profile: guest, isLoggedIn: true }));
    // sendLoginStateInGA(true, window.location.href);
  };

export const logout = (): AppThunk => async (dispatch: AppDispatch) => {
  await dispatch(clearMember());
  await dispatch(clearRecentSearch());
  sendLoginStateInGA(false, window.location.href, null);
  if (window.location.pathname.indexOf("/account") == -1) {
    addHiddenIframe("authIframe", "/auth-logout"); //no redirection after logout
  } else {
    addHiddenIframe("authIframe", "/auth-logout"); //no redirection after logout
  }
};

export const addCRMProfile =
  (tokenId: string, baseProfile = false): AppThunk =>
  async (dispatch: AppDispatch, _getState) => {
    if (tokenId) {
      const profile = await getMemberProfile(tokenId, baseProfile);
      if (profile == "forbidden") {
        await dispatch(clearMember());
      } else {
        if (profile) {
          dispatch(memberSlice.actions.setCRMProfile(profile));
        } else {
          dispatch(
            memberSlice.actions.setError("Error in fetching the profile")
          );
        }
      }
    }
  };

export const addCRMProfileCDP =
  (tokenId: string, baseProfile = false): AppThunk =>
  async (dispatch: AppDispatch, _getState) => {
    if (tokenId) {
      try {
        const memberService = await memberServiceProvider();
        const profile = await memberService.getMemberProfile();
        const userId = profile?.cDPUserProfile?.memberId;
        if (userId) {
          const response = await getMemberPoints(userId);
          if (
            response?.memberPointsData &&
            response?.memberPointsData?.available
          ) {
            dispatch(setMemberPointsTally(response.memberPointsData.available));
          } else {
            dispatch(setMemberPointsTally(0));
          }

          const userTripData = getUserTripsDataforGA(
            profile?.cDPUserProfile?.trips
          );
          const userData = {
            memberId: userId,
            profileType: profile?.cDPUserProfile
              ? profile?.cDPUserProfile?.profileType
              : "NA",
            upcomingTrip: userTripData?.upcomingTrip ? "Yes" : "No",
            daysLeftForUpcomingTrip: userTripData?.daysLeftForUpcomingTrip
              ? userTripData?.daysLeftForUpcomingTrip
              : "NA",
            hotelIdOfLastStay: userTripData?.hotelIdOfLastStay
              ? userTripData?.hotelIdOfLastStay
              : "NA",
            rewardPoints: response?.memberPointsData
              ? response.memberPointsData.available
              : 0,
          };
          sendLoginStateInGA(true, window.location.href, userData);
        }
        dispatch(memberSlice.actions.setCRMProfile(profile?.cDPUserProfile));
      } catch (error) {
        dispatch(memberSlice.actions.setError("Error in fetching the profile"));
        console.error(error);
        throw error;
      }

      // if (profile == "forbidden") {
      //   await dispatch(clearMember());
      // } else {
      //   if (profile) {
      //     dispatch(memberSlice.actions.setCRMProfile(profile));
      //   } else {
      //     dispatch(
      //       memberSlice.actions.setError("Error in fetching the profile")
      //     );
      //   }
      // }
    }
  };

export const addReservations =
  (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const profile: any = getState().member.crmProfile;
    if (profile) {
      // let resObj = profile.reservations;
      const reservations = [{}]; //profile.visits;
      const stays = profile.trips;

      if (reservations || stays) {
        let upcomingRes: any[] = [],
          cancelledRes: any[] = [],
          recentStays: any[] = [];
        const parseDateFormat = "MM/DD/YYYY h:mm:ss a";
        // const todayDate = moment();
        stays &&
          stays.forEach((stay: any) => {
            const trip = stay.reservations[0];
            if (trip.confirmNumber) {
              const res = {
                arrival: moment(trip.startDate).format(parseDateFormat),
                departure: moment(trip.endDate).format(parseDateFormat),
                crs_reservation_id: trip.confirmNumber,
                confirmationNo: trip.confirmNumber,
                reservationstatus: trip.status,
                pms_property_code: trip.LocationID,
                guestFirstName: trip.guestFirstName,
                guestLastName: trip.guestLastName,
                redeemedPoints: trip.redemption?.redeemedPoints,
              };
              if (trip.status == "COMPLETED") {
                recentStays.push(res);
              } else if (trip.status == "CANCELLED") {
                cancelledRes.push(res);
              } else if (trip.status == "UPCOMING") {
                if (moment(trip.startDate).isBefore(moment())) {
                  recentStays.push(res);
                } else {
                  upcomingRes.push(res);
                }
              }
            }
          });

        upcomingRes = convertArrayToObject(upcomingRes, "crs_reservation_id");
        recentStays = convertArrayToObject(recentStays, "crs_reservation_id");
        cancelledRes = convertArrayToObject(cancelledRes, "crs_reservation_id");

        dispatch(setUpcomingRes(upcomingRes));
        dispatch(setRecentStays(recentStays));
        dispatch(setCancelledRes(cancelledRes));
      }
    }
  };

export const cancelReservation =
  (params: {
    hotelCode: string;
    resId: string;
    memberId: string;
    isRedemption: boolean;
    lastName: string;
  }): any =>
  async (dispatch: AppDispatch, getState) => {
    // const cancelledResponse: any = await cancelReservationAPI(params);
    //const guestService = await guestServiceProvider();
    //const cancelResponse = await guestService.cancelReservation(params.resId);

    try {
      const cancelResponseRedemtion = await cancelRedemtionReservation(params);
      // const cancelID = cancelledResponse.cancelID;
      const cancelID =
        cancelResponseRedemtion?.cancelReservationResponse?.cancellationId;
      if (cancelID) {
        const resId = params.resId;
        const upcomingReservations: any =
          getState().member.upcomingReservations;
        const cancelledReservations: any =
          getState().member.cancelledReservations;

        const { [resId]: changedRes, ...rest } = upcomingReservations;
        const cancelledRes = changedRes
          ? { [resId]: { ...changedRes, cancelID: cancelID } }
          : {};
        dispatch(setUpcomingRes(rest));
        dispatch(
          setCancelledRes({ ...cancelledReservations, ...cancelledRes })
        );
        dispatch(clearError);
        dispatch(
          updateRequestStatus({
            type: "cancel-reservation",
            cancelID: cancelID,
            status: "success",
          })
        );
      } else {
        dispatch(
          updateRequestStatus({
            type: "cancel-reservation",
            status: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        updateRequestStatus({
          type: "cancel-reservation",
          status: "error",
        })
      );
    }
  };

//Selectors
export const isLoggedInSelector = (state: RootState): boolean =>
  state.member.isLoggedIn;
export const crmProfileSelector = (state: RootState): any =>
  state.member.isLoggedIn && state.member.crmProfile;
