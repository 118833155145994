import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Axios, { CancelTokenSource } from "axios";
import { guestServiceProvider } from "../../../services/ServiceProvider";
import { IHotelReferences } from "../../../templates/CityPage/CityPageProps";
import { AppDispatch, AppThunk, RootState } from "../../store";

// export interface IHotelState {
//   hotels?: Array<IHotel>;
//   selectedHotel?: IHotel;
// }

const _initialState = {
  isLoading: true,
  hotels: {},
};

const hotelSlice = createSlice({
  name: "hotel",
  initialState: _initialState,
  reducers: {
    selectHotels: (state: any, { payload }: PayloadAction<any>) => {
      state.hotels = payload;
      return state;
    },
    setIsLoading: (state: any, { payload }: PayloadAction<any>) => {
      state.isLoading = payload;

      return state;
    },
    resetHotelState: (state: any) => {
      return { ..._initialState };
    },
    addHotelAmenities: (state: any, { payload }: PayloadAction<any>) => {
      const hotel = state?.hotels[payload?.id];
      if (hotel) {
        // Directly assign the new amenities to the draft state
        hotel.hotelCardAmenities = payload.amenities || [];
      }
    },
  },
});

export const hotelReducer = hotelSlice.reducer;
export const {
  selectHotels,
  setIsLoading,
  resetHotelState,
  addHotelAmenities,
} = hotelSlice.actions;

//Async actions
// Create a queue to store the loadHotels calls
interface LoadHotelsParams {
  distance?: number;
  latitude?: number | null;
  longitude?: number | null;
  siteId?: number;
  source?: CancelTokenSource | null;
  hotelReferences: Array<IHotelReferences>;
  searchType?: string;
  cityName?: string;
}

type LoadHotelsQueueItem = {
  params: LoadHotelsParams;
};

let debounceTimer: NodeJS.Timeout | null = null;

export const loadHotels =
  (
    distance?: number,
    latitude?: number | null,
    longitude?: number | null,
    siteId?: number,
    source?: CancelTokenSource | null,
    hotelReferences?: Array<IHotelReferences>,
    searchType?: string | null,
    cityName?: string
  ): AppThunk =>
  async (dispatch) => {
    const cancelTokenSource = Axios.CancelToken.source();
    if (source) {
      source.cancel("Cancelling previous request");
    }

    source = cancelTokenSource;

    dispatch(hotelSlice.actions.setIsLoading(true));
    const dispatchLoadHotels = async ({
      distance,
      latitude,
      longitude,
      siteId,
      source,
      hotelReferences,
      searchType,
      cityName,
    }: LoadHotelsParams) => {
      try {
        if (hotelReferences && hotelReferences.length > 0) {
          const hotels = hotelReferences.reduce((acc, item) => {
            const { crsHotelCode, ...rest } = item;
            acc[crsHotelCode] = { crsHotelCode, ...rest };
            return acc;
          }, {});

          dispatch(hotelSlice.actions.selectHotels(hotels));
          dispatch(hotelSlice.actions.setIsLoading(false));
        } else {
          const guestService = await guestServiceProvider();
          const hotelProximity = await guestService.getHotelProximity(
            distance,
            latitude,
            longitude,
            {
              type:
                searchType === "locality" || searchType === "city"
                  ? "locality"
                  : null,
              cityName: cityName,
            }
          );

          if (hotelProximity?.searchHotelsByDistance?.results) {
            const hotels =
              hotelProximity?.searchHotelsByDistance?.results.reduce(
                (acc, hotel, index) => {
                  acc[hotel.crsHotelCode] = { ...hotel, index: index };
                  return acc;
                },
                {}
              );
            dispatch(hotelSlice.actions.selectHotels(hotels));
            dispatch(hotelSlice.actions.setIsLoading(false));
          }
        }
      } catch (error) {
        dispatch(hotelSlice.actions.setIsLoading(false));
        console.error(error);
      }
    };

    if (distance && latitude && longitude) {
      if (debounceTimer) {
        clearTimeout(debounceTimer); // Clear the previous timer if it exists
      }

      debounceTimer = setTimeout(() => {
        // Set a new timer
        debounceTimer = null; // Reset the timer variable

        dispatchLoadHotels({
          distance,
          latitude,
          longitude,
          siteId,
          source,
          hotelReferences,
          searchType,
          cityName,
        });
      }, 500);
    }
  };

interface LoadInterestHotelParams {
  distance?: number;
  latitude?: number | null;
  longitude?: number | null;
  siteId?: number;
  url?: string;
  source?: CancelTokenSource | null;
}

export const loadInterestsHotels =
  (
    distance?: number,
    latitude?: number | null,
    longitude?: number | null,
    siteId?: number,
    url?: string,
    source?: CancelTokenSource | null,
    hotelReferences?: Array<IHotelReferences>
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const cancelTokenSource = Axios.CancelToken.source();
    if (source) {
      source.cancel("Cancelling previous request");
    }

    source = cancelTokenSource;
    dispatch(hotelSlice.actions.setIsLoading(true));
    const dispatchLoadInterestHotels = async ({
      distance,
      latitude,
      longitude,
      siteId,
      url,
      source,
    }: LoadInterestHotelParams) => {
      try {
        if (hotelReferences && hotelReferences.length > 0) {
          const hotels = hotelReferences.reduce((acc, item) => {
            const { crsHotelCode, ...rest } = item;
            acc[crsHotelCode] = { crsHotelCode, ...rest };
            return acc;
          }, {});

          dispatch(hotelSlice.actions.selectHotels(hotels));
          dispatch(hotelSlice.actions.setIsLoading(false));
        } else {
          const guestService = await guestServiceProvider();
          const hotelByInterests = await guestService.getHotelByInterest(
            distance,
            latitude,
            longitude,
            url
          );
          if (hotelByInterests?.searchHotelsByInterest?.results) {
            const hotels =
              hotelByInterests?.searchHotelsByInterest?.results.reduce(
                (acc, item) => {
                  const { crsHotelCode, ...rest } = item;
                  acc[crsHotelCode] = { crsHotelCode, ...rest };
                  return acc;
                },
                {}
              );
            dispatch(hotelSlice.actions.selectHotels(hotels));
            dispatch(hotelSlice.actions.setIsLoading(false));
          }
        }
      } catch (error) {
        dispatch(hotelSlice.actions.setIsLoading(false));
        console.error(error);
      }
    };
    if (distance && latitude && longitude) {
      if (debounceTimer) {
        clearTimeout(debounceTimer); // Clear the previous timer if it exists
      }

      debounceTimer = setTimeout(() => {
        // Set a new timer
        debounceTimer = null; // Reset the timer variable

        dispatchLoadInterestHotels({
          distance,
          latitude,
          longitude,
          siteId,
          url,
          source,
        });
      }, 500);
    }
  };

//Selectors
export const hotelsSelector = (state: RootState): any => state.hotels;
